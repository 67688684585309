<template>
  <div
    v-if="hasErrorMessage"
    class="visitor-error-message"
    :class="{
      bold: bold,
      center: center,
      small: small,
      large: large,
      whitespace: whitespace,
      [textClasses]: true
    }"
  >
    {{ errorMessage }}
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from "vue"
export default {
  name: "VisitorErrorHandler",
  props: {
    error: {
      type: Object,
      default: null
    },
    keepAlive: {
      type: Boolean,
      default: false
    },
    textClasses: {
      type: String,
      default: ""
    },
    bold: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    whitespace: {
      type: Boolean,
      default: false
    }
  },
  emits: ["done"],
  setup(props, { emit }) {
    const timeoutId = ref(null)

    const formatErrorMessage = (msg) => {
      if (!msg) return ""

      const lowerMsg = msg?.toString()?.toLowerCase() || ""

      if (
        lowerMsg.includes("failed to execute 'subscribe' on 'pushmanager'") ||
        lowerMsg.includes("permission-blocked")
      ) {
        return "Please grant Notification permission in your browser and refresh your page for your permission to apply."
      } else if (
        lowerMsg.includes("firebase requires notification permission")
      ) {
        return "Firebase requires Notification permission. Please grant Notification permission in your browser and then refresh for your page to apply."
      } else if (lowerMsg.includes("failed to register a serviceworker")) {
        return "Your browser does not support Service Worker. Please use a different browser."
      }

      return msg
    }

    const errorMessage = computed(() => {
      const msg =
        props?.error?.response?.data?.message || props?.error?.message || ""
      return formatErrorMessage(msg)
    })

    const hasErrorMessage = computed(() => {
      return errorMessage?.value?.trim()?.length > 0 || false
    })

    onMounted(() => {
      const time = props.keepAlive ? 60000 : 30000
      timeoutId.value = setTimeout(() => {
        emit("done")
      }, time)
    })

    onUnmounted(() => {
      if (timeoutId.value) clearTimeout(timeoutId.value)
    })

    return {
      hasErrorMessage,
      errorMessage
    }
  }
}
</script>

<style scoped></style>
